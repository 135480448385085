.button-component{
    display: block;
    border-radius: 10px;
    background: linear-gradient(82deg, #313860 2.25%, #151928 79.87%);
    color: #FFF;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px;
    margin-right: 10px;
    align-self: flex-end;
    border: none;
    transition: all 0.3s ease-in-out;
}

.button-component.centered{
    align-self: center !important;
}
.button-component.full-width{
    width: 100% !important;
}
.button-component.white{
    background: linear-gradient(82deg, #FFF 2.25%, #FFF 79.87%);
    color: #313860;
}
.button-component.bordered{
    border: 1px solid #313860;
}

.button-component.white:hover{
    background: linear-gradient(82deg, #313860 2.25%, #151928 79.87%);
    color: #fff;
}

.button-component:hover{
    background: linear-gradient(82deg, #FFF 2.25%, #FFF 79.87%);
    color: #313860;
}

.button-component.not-hover:hover{
    background: linear-gradient(82deg, #313860 2.25%, #151928 79.87%);
    color: #fff;
}