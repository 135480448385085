.mobile-nav{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 1;
    transition: all 1s cubic-bezier(0.23, 0.33, 0, 1.57);
}

.mobile-nav.collapsed{
    opacity: 0;
    height: 0;
    user-select: none;
    z-index: -1;
}

.mobile-nav .close{
    border-radius: 12px;
    background: #313860;
    padding: 16px;
    width: fit-content;
    height: fit-content;
}

.mobile-nav .gapped-side{
    display: flex;
    gap: 16px;
    margin-top: 10px;
}



.mobile-nav .side-menu{
    color: #8A92A6;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 175%; /* 28px */
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 15px;
}

.mobile-nav .side-menu.active{
    
    color: #fff;
    border-radius: 10px;
    background: #313860;

    /* card1 */
    box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
}


.mobile-nav .side-menu .menu-icon path {
    stroke: #313860;
}

.mobile-nav .side-menu.active .menu-icon path{
    stroke: #ffffff;
}