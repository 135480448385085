.table-card{
    border-radius: 10px;
    background: var(--black-amp-white-white, #FFF);
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.25);
    padding-top: 10px;
    padding-bottom: 10px;
}

.table-card .icon{
    border-radius: 10px;
    width: 100%;
}

.table-card .title{
    color: var(--gray-gray-400, #A0AEC0);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 15px */
}
.table-card .value{
    color: var(--gray-gray-700, #2D3748);
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
}