.card{
    background-color: var(--black-amp-white-white);
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    cursor: pointer;
    transition: all 0.1s linear;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .card{
        margin: 20px 0;
    }
}

.card:hover,.card:active{
    background: url("../../images/assets/card-bg.svg") no-repeat center center / cover;
}

.card .up-arrow{
    width: 48px;
}

.card .icon{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%, -50%);
    background-color: #FA00FF;
    z-index: 9;
    padding: 12px;
    border-radius: 10px;
}



.card .title{
    color: #313860;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
}

.card:hover .title{
    color: #fff;
}
.card .desc{
    color: #1BDC97;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    transition: all 0.1s linear;
}
.card .value{
    color: #313860;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    transition: all 0.1s linear;
}

.card:hover .value{
    color: #fff;
}

.card .more-link a{
    text-decoration: none;
    color: #FA00FF;
    text-align: right;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.card:hover .title,.card:hover .value{
    color: #fff;
}