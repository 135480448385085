.dropzone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 10%;
    right: 10%;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone .file{
    width: 100%;
    height: 100%;
    object-fit: cover;
}