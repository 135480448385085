@media screen and (max-width: 765px) {
    .loginForm{
        min-width: 100% !important;
    }
    .sidebar{
        display: none !important;
    }
    .content-area{
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
    }
    .header-content{
        display: none !important;
    }
    .mobile-header{
        display: block !important;
        position: relative;
        border-radius: 10px;
        background: var(--black-amp-white-white, #FFF);
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 20px;
    }
    .mobile-header .mobile-header-content{
        border-radius: 10px;
        background: var(--black-amp-white-white, #FFF);
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
        

    }

    .mobile-header .mobile-menu-icon{
        border-radius: 12px;
        background: #313860;
        padding: 10px;
        width: fit-content;
        height: fit-content;
    }

    .header{
        height: 200px;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
    }

    .header-bg{
        object-fit: cover;
        height: 300px;
        left: 0;
    }


    .table-content-tr th:first-child{
        display: none;
    }
    
    .table-content-tr td:first-child{
        display: none;
    }

    .button-component {
        font-size: 12px;
        padding: 10px 20px;
    }
}

@media screen and (max-width: 900px) {
    .table-content{
        max-width: 100vw;
        overflow-y: auto;
        display: block;
    }
    .table-content-tr th:first-child{
        display: none;
    }
    
    .table-content-tr td:first-child{
        display: none;
    }
}