.sidebar{
    position:fixed;
    left:20px;
    top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh; 
    z-index: 999;
}

.sidebar .nav{
    overflow: scroll;
    background: var(--black-amp-white-white, #FFF);
    box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 257px;
    z-index: 99;
    transition: width 0.5s cubic-bezier(0.23, 0.33, 0, 1.57);
}

.sidebar .nav .toggle{
    position: absolute;
    right: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    background-color: #313860;
    transform: translateX(50%);
    padding: 0;
}
.sidebar .nav .nav-item{
    margin-bottom: 8px;
}

.sidebar .logo{
    display: block;
    width: 100%;
    margin-bottom: 30px;
}
.sidebar .logo-collapsed{
    display: none;
}
.sidebar .sidebar-icon{
    width: 24px;
    height: 24px;
}

.sidebar .title{
    font-size: 16px;
}

.sidebar .menu-item{
    list-style-type: none;
}

.sidebar .panel-menus{
    max-height: 75%;
    overflow-y: auto;
}

.sidebar .accordion-item{
    border:  none !important;
}

.sidebar .accordion-item .accordion-button.collapsed{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar .accordion-item .accordion-button{
    border-radius: 10px;
    background-color: #fff !important;
    color: #8A92A6;
    font-family: Helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    line-height: 24px;
}

.sidebar .accordion-item.active .accordion-button{
    border-radius: 10px;
    color: #FFF;
    background-color: #313860 !important;
    box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
}
.sidebar .accordion-item .accordion-button:focus{
    border-radius: 10px;
    color: #FFF;
    background-color: #313860 !important;
    box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
}

.sidebar .accordion-button.collapsed::after{
    background-image: url("../../images/assets/icons/not-collapsed.svg");
}
.sidebar .accordion-button::after{
    background-image: url("../../images/assets/icons/collapsed.svg");
}

.sidebar.collapsed .top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar .accordion-item .menu-icon path {
    stroke: #313860;
}

.sidebar .accordion-item.active .menu-icon path{
    stroke: #ffffff;
}

.sidebar .accordion-item .accordion-button:focus > .menu-icon path{
    stroke: #ffffff;
}

.sidebar .bottom{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    width: 100%;
    justify-content: center;

}



.sidebar .side-menu{
    color: #8A92A6;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 175%; /* 28px */
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 15px;
}

.sidebar.collapsed .bottom-container .collapsed{
    display: none;
}

.sidebar.collapsed .bottom-container{
    justify-content: center;
    align-items: center;
}

.sidebar.collapsed .bottom-container .row{
    width: 100%;
}

.sidebar.collapsed .bottom-container .center-text{
    align-items: center;
    justify-content: center;
}
.sidebar.collapsed .bottom-container .question{
    width: 50px;
    height: 50px;
}

.sidebar .side-menu.active{
    
    color: #fff;
    border-radius: 10px;
    background: #313860;

    /* card1 */
    box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
}


.sidebar .side-menu .menu-icon path {
    stroke: #313860;
}

.sidebar .side-menu.active .menu-icon path{
    stroke: #ffffff;
}


.sidebar .sub-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 0;
}

.sidebar .menu-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}   


.sidebar.collapsed .nav{
    width: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar.collapsed .logo{
    display: none;
}

.sidebar.collapsed .logo-collapsed{
    width: 100%;
    display: block;
    margin-bottom: 30px;
}

.sidebar.collapsed .text{
    display: none;
}

.sidebar.collapsed .accordion-button::after{
    display: none;
}

.sidebar .sidebar-icon{
    width: 24px;
    height: 24px;
}

.sidebar .passive-color{
    color: var(--sidebar-passive, #8A92A6);
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
}

.sidebar .active-color{
    color: var(--sidebar-active, #8A92A6);
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
}

.sidebar .gapped-side{
    display: flex;
    gap: 16px;
}




.bottom{
    position: fixed;
    right: 1%;
    bottom: 1%;
    z-index: 9999;
    display: flex;
    cursor: pointer;
}


.bottom .bottom-container .collapsed{
    display: none;
}

.bottom:hover .bottom-container .collapsed{display: flex;}

.bottom .bottom-container{
    justify-content: center;
    align-items: center;
}

.bottom .bottom-container .row{
    width: 100%;
}

.bottom .bottom-container .center-text{
    align-items: center;
    justify-content: center;
}
.bottom .bottom-container .question{
    width: 25px;
    height: 25px;
}

.bottom:hover .bottom-container .question{
    width: 50px;
    height: 50px;
}



.bottom .box{
    width: 50px;
    border-radius: 20px;
}

.bottom:hover .box{
    position: relative;
    width: 110%;
}


.bottom-container{
    position: absolute;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    color: #fff;
}

.bottom-container .center-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bottom-container .question{
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.bottom-container .bottom-heading{
    color: var(--black-amp-white-white, #FFF);
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.bottom-container .bottom-text{
    color: var(--black-amp-white-white, #FFF);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
}

.bottom-container .bottom-button {
    color: var(--gray-gray-700, #2D3748);
    text-align: center;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
}

.save-button {
    color: var(--gray-gray-700, #dfe2e7);
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    background-color: #313860;
    padding: 10px 0;
}
.save-button:hover {
    color: var(--gray-gray-700, #dfe2e7);
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    background-color: #2D3748;
    padding: 10px 0;
}
.modal-content {
    border-radius: 3px!important;
}