.theme-container{
    border-radius: 10px;
    background: var(--black-amp-white-white, #FFF);
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.theme-container.row-layout{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.theme-container.row-layout .button-component{
    align-self: center !important;
}

.row-layout{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.row-layout .button-component{
    margin-left: 10px;
    align-self: center !important;
}

.theme-container .title{
    color: #313860;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.theme-container .desc{
    color: #8A92A6;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}