/* import input css */


/* init root colors */ 

:root{
    --gray-gray-200: #E2E8F0;
    --gray-gray-400: #A0AEC0;
    --black-amp-white-white: #FFF;
    --sidebar-passive: #8A92A6;
    --sidebar-active: #313860;
}

body{
    background-color: #F8F9FA;
}

.fluid{
    height: 100vh;
}

.loginForm{
    min-width: 500px;
    display: flex;
    flex-direction: column;
}

.alertContainer{
    height: 100vh;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
    pointer-events: none;
    right: 10px;
    top: 10px;
}

.content-area{
    min-height: 100vh;
    padding-left: 320px;
    position: relative;
    z-index: 99;
    margin-top: -10%;
    transition: all 0.3s cubic-bezier(0.23, 0.33, 0, 1.57);
}

.content-area.collapsed{
    padding-left: 180px;
}

.grayText{
    color: var(--gray-gray-400, #A0AEC0);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.gapped-container{
    display: flex;
    gap:  10px 0;
}

.logoutButton{
    cursor: pointer;
}

.mobile-header{
    display: none;
}

.pink-text{
    cursor: pointer;
    color: #FA00FF !important;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.custom-progress{
    height: 3px;
}
.custom-progress .up{
    background: #1BDC97;
}
.custom-progress .down{
    background: #FC3030;
}
.custom-progress .stable{
    background: #8A92A6;
}

.progress-text{
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
}

.progress-text.up{
    color: #1BDC97;
}

.progress-text.down{
    color: #FC3030;
}

.progress-text.stable{
    color: #8A92A6;
}

.gameIcon{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.login-bg-img{
    background-image: url("../images/assets/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: -1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.login-bg-img .loginForm{
    background-color: #FFF;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-bg-img .loginForm .loginText{
    color: #313860;
    text-align: center;
    font-family: Chakra Petch;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */   
}

.loginInput{
    text-align: center;
}
.loginInput::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */

.loginInput::-webkit-input-placeholder {
    text-align: center;
}

.loginInput:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

.loginInput::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

.loginInput:-ms-input-placeholder {  
    text-align: center; 
}


.input::placeholder {
    color: rgba(49, 56, 96, 0.4);
}

.input:-moz-placeholder{
    color: rgba(49, 56, 96, 0.4);
}

.input:-ms-input-placeholder {
    color: rgba(49, 56, 96, 0.4);
} 

.form-control:focus {
    border-color: #313860;
    box-shadow: 0px 4px 10px rgba(49, 56, 96, 0.25);
}


@import "./components/input.css";
@import "./components/button.css";
@import "./components/sidebar.css";
@import "./components/authHeader.css";
@import "./components/card.css";
@import "./components/themeContainer.css";
@import "./components/siteNav.css";
@import "./components/fileUpload.css";
@import "./components/mobile-nav.css";
@import "./components/table-card.css";
@import "./components/Table.css";


@import "./reponsive.css";
