.header{
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
}


.header .header-bg {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
}

.header .header-content {
    height: fit-content;
    position: relative;
    left: 0;
    top: 0;
    padding-left: 320px;
    padding-top: 60px;
    padding-bottom: 10px;
    transition: all 0.3s cubic-bezier(0.23, 0.33, 0, 1.57);
    height: 300px;
}

.header .header-content.collapsed {
    padding-left: 180px;
}


.header .user-span{
    color: #ffffff;
    height: fit-content;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.header .header-content .breadcrump {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 20px;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.header .header-content .breadcrump div{
    display: flex;
    gap:5px;
}

.header .header-content .breadcrump .breadcrump-item:first-child{
    color: var(--gray-gray-400, #A0AEC0);
}
.header .header-content .breadcrump .breadcrump-item{
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.header .header-content .breadcrump h1 {
    color: #FFF;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.header .header-content .website-show-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.header .header-content .website-show-area .header-title{
    color: #FFF;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
}

.header .header-content .website-show-area img{
    width: 40px;
    height: 40px;
}

.header .header-content .search-area {
    position: relative;
    height: fit-content;
}


.header .header-content .search-area .search-input {
    width: 100%;
    padding: 15px 20px ;
    border-radius: 5px;
    background-color: #FFF;
    border: none;
    padding-left: 40px;
    padding-right: 40px;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--gray-gray-400, #A0AEC0);
}

.header .header-content .search-area img{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(50%, -50%);
}