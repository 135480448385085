.collapce-class{
    /* position: absolute;
    left: 0;
    top: 0;
    transform: translateY(50%);
    width: 100%; */
}


.table-content-tr {
    position: relative;
}
.table-content-tr td{
    text-align: center;
    vertical-align: middle;
}

.table-content-tr td:first-child{
    text-align: left;
}

.table-content-tr th{
    text-align: center;
    vertical-align: middle;
}

.table-content-tr th:first-child{
    text-align: left;
}

.table-page .heading-text{
    color: var(--gray-gray-400, #A0AEC0);
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 15px */
}

.table-page .content-text{
    color: var(--gray-gray-700, #2D3748);
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
}

.table-page .analytics-text{
    color: #313860;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
}

.table-page .analytics-desc-text{
    color: #8A92A6;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}