.input-component{
    display: block;
    margin: 15px 0;
}

.input-component .title{
    color: #313860;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.input-component .description{
    color: var(--gray-gray-400, #A0AEC0);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.input-component .input{
    border-radius: 10px;
    border: 0.5px solid var(--gray-gray-200, #E2E8F0);
    background: var(--black-amp-white-white, #FFF);
    padding: 15px 20px;
    resize: none;
}
